<template>
  <div class="product-page--create">
    <v-row no-gutters>
      <v-col cols="12">
        <redirect class="mb-5" title="Size Chart" route="website.sizechart" />
        <div class="page-header">
          <h2 class="page-title">{{ attributes.style }}</h2>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <Notification type="error" :messages="errors" v-if="errors.length > 0" />
      </v-col>
      <v-col cols="12" sm="8" class="d-flex flex-column">
        <div class="elevation-form pa-3 mb-4">
          <div><strong>Product Type</strong></div>
          <v-autocomplete chips :search-input.sync="search" :items="document" v-model="attributes.tag" multiple>
            <template v-slot:selection="{ item, index }">
              <!-- <v-chip v-if="index === 0">
                <span>{{ item.title }}</span>
              </v-chip> -->
              <span v-if="index === 0 && item" class="grey--text px-4"> Search product type </span>
            </template>
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    No results matching " <strong>{{ search }}</strong> ".
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
          <v-row v-if="attributes.tag.length > 0">
            <v-col cols="12" class="py-0 m-0">
              <v-list class="">
                <v-list-item-group>
                  <v-list-item v-for="(item, i) in attributes.tag" :key="i">
                    <v-row>
                      <v-col cols="9" class="px-0 py-0 m-0 d-flex align-center">
                        <span class="primary--text">{{ item }}</span>
                      </v-col>
                      <v-col cols="3" class="px-0 py-0 m-0 text-right">
                        <v-btn @click="removeProductType(item)" class="btn-link">
                          <v-icon small>fas fa-times</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
          <div class="font-italic mt-3 fs14">The created size chart will appear on products with this tag</div>
        </div>
        <div class="elevation-form pa-3 text-center side-guide mb-3">
          <InputDefault
            :min="0"
            :max="255"
            :model="['attributes', 'style']"
            fieldName="Style"
            label="Style"
            :isValidate="true"
          />
        </div>
        <div class="elevation-form pa-3">
          <h3 class="mb-2">Content</h3>
          <v-expansion-panels accordion class="list-section ma-1" style="max-height:1500px !important;">
            <draggable v-model="attributes.sizeCharts" style="width: 100%; border: 1px solid #ddd;">
              <transition-group>
                <v-expansion-panel v-for="(item, i) in attributes.sizeCharts" :key="`size chart item${i}`">
                  <v-expansion-panel-header expand-icon="mdi-menu-down" class="heading">
                    <i class="fas fa-bars mr-2" style="flex:none !important"></i>
                    {{ `Content ${i + 1}` }}</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <h4 class="mb-2">Title</h4>
                    <v-text-field type="text" v-model="item.title"></v-text-field>
                    <div v-for="(des, j) in item.attributes" :key="`size chart item${j}`">
                      <div class="pa-3 border-child">
                        <div class="d-flex align-center justify-space-between" @click="activeChild(i, j)">
                          <div>
                            <i class="fas fa-bars mr-2" style="flex:none !important"></i>
                            {{ `Child ${j + 1}` }}
                          </div>
                          <i class="fas fa-caret-down"></i>
                        </div>
                        <div v-if="active && i == active.i && j == active.j">
                          <h4 class="mb-2 mt-4">Title</h4>
                          <v-text-field type="text" v-model="des.title"></v-text-field>
                          <div>
                            <ContentInput
                              @change="change"
                              title="Description"
                              :height="600"
                              subtitle="Maximum 100.000 characters"
                              :dataSet="item"
                              :index="{ i: i, j: j }"
                              :data="des.descriptionHtml"
                            />
                          </div>
                          <div style="border: 1px solid #ddd;" class="my-4">
                            <testimg :index="{ i: i, j: j }" :src="des.imageUrl" @setImage="setImage" />
                          </div>
                          <div class="d-flex align-center justify-end">
                            <v-btn text @click="removeAttribute(i, j)">Remove content</v-btn>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mx-1 mt-2 d-flex align-center justify-space-between">
                      <v-btn text @click="removeContent(i)">Remove content</v-btn>
                      <v-btn color="primary" @click="addAttributes(i)">+ Add Child</v-btn>
                    </div>
                    <!-- <ContentInput
                      @change="change"
                      title="Description"
                      subtitle="Maximum 100.000 characters"
                      :dataSet="item"
                      :index="i"
                      :data="item.descriptionHtml"
                    />
                    <div style="border: 1px solid #ddd;" class="my-4">
                      <testimg :index="i" :src="item.imageUrl" @setImage="setImage" />
                    </div>
                    <div>
                      <v-btn>Remove content</v-btn>
                    </div> -->
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </transition-group>
            </draggable>
          </v-expansion-panels>
          <div class="mx-1 mt-2">
            <v-btn color="primary" @click="addContent">+ Add content</v-btn>
          </div>
          <!-- <InputDefault
            :min="0"
            :max="255"
            :model="['attributes', 'style']"
            fieldName="Style"
            label="Title"
            :isValidate="true"
          />
          <div>{{ attributes.title.length }}/255</div> -->
          <!-- <ContentInput title="Description" subtitle="Maximum 100.000 characters" :model="'descriptionHtml'" /> -->
          <!-- <testimg /> -->
        </div>
        <!-- <div class="elevation-form mt-4 pa-3">
          <testimg />
        </div> -->
      </v-col>
      <v-col cols="12" sm="4" class="d-flex flex-column size-chart-preview">
        <!-- <div class="elevation-form pa-3">
          <ProductAvailability />
        </div> -->
        <div class="elevation-form pa-3 ma-2 text-center side-guide">
          <!-- <Organization /> -->
          <h3 class="mb-2">SIDE GUIDE</h3>
          <div class="sideguide-content mb-2">{{ attributes.style }}</div>
          <div v-if="attributes.sizeCharts && attributes.sizeCharts.length > 1" class="my-4">
            <span
              v-for="(item, i) in attributes.sizeCharts"
              :key="`preview title sizeCharts ${i}`"
              class="px-2 preview-title"
              :class="{ 'title-active': previewActive === i }"
              @click="activeSizeChart(i)"
            >
              <!-- <div class="d-flex justify-space-between align-center"> -->
              <span>{{ item.title }}</span>
              <!-- </div> -->
            </span>
          </div>
          <div
            v-if="sizeChartActive && sizeChartActive.attributes && sizeChartActive.attributes.length > 1"
            class="my-4 d-flex justify-start align-center"
          >
            <span
              v-for="(item, i) in sizeChartActive.attributes"
              :key="`preview content sizeCharts ${i}`"
              class="px-2 py-1 preview-content"
              :class="{ 'content-active': previewChildActive === i }"
              @click="activeContentChild(i)"
            >
              <!-- <div class="d-flex justify-space-between align-center"> -->
              <span>{{ item.title }}</span>
              <!-- </div> -->
            </span>
          </div>
          <div v-if="contentChildActive">
            <v-img
              class="mx-auto"
              v-if="contentChildActive.imageUrl"
              :src="imageHelpers.url(contentChildActive.imageUrl, { width: 500, height: 500 })"
              max-height="200"
              max-width="200"
            ></v-img>
            <div class="mt-5" v-html="contentChildActive.descriptionHtml"></div>
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" class="d-flex">
        <v-btn @click="statusOfDialog = true" color="error" elevation="">Delete</v-btn>
        <v-spacer></v-spacer>
        <v-btn outlined class="mr-3" @click="onCancel">Discard</v-btn>
        <v-btn :loading="isLoading" color="primary" @click="onSubmit">Save</v-btn>
      </v-col>
    </v-row>
    <!-- <delete-action :content="'product ' + name" :title="'Delete ' + name" /> -->
    <ContentDialog
      :itemName="name"
      :actionName="actionName"
      :pretext="pretext"
      :surtext="surtext"
      :statusOfDialog="statusOfDialog"
      :leftButton="leftButton"
      :rightButton="rightButton"
      @closeDialog="closeDialog"
      @deleteItem="deleteItem"
    />
    <BeforeLeavePage />
  </div>
</template>

<script>
import utilities from '@/helpers/utilities';
import { productApi } from '@/apis/product';
import { required, minLength, maxLength, between, email } from 'vuelidate/lib/validators';
import { sizechartApi } from '@/apis/sizecharts';
import ContentInput from './component/Editor';
import testimg from '@/views/website/sizechart/component/Image';
import imageHelpers from '@/helpers/image';
import ContentDialog from './ContentDialog.vue';
import draggable from 'vuedraggable';
export default {
  components: {
    ContentInput,
    testimg,
    draggable,
    ContentDialog,
  },
  async created() {
    this.loading = true;
    await this.getData();
    this.loading = false;
  },
  data() {
    return {
      search: '',
      document: [],
      attributes: {
        tag: '',
        style: '',
        imageUrl: null,
        descriptionHtml: '',
      },
      isLoading: false,
      errors: [],
      idItem: null,
      random: '',
      imageHelpers,
      pretext: 'Bạn có chắc chắn muốn',
      name: '',
      actionName: 'xóa',
      surtext: 'không ?',
      statusOfDialog: false,
      leftButton: 'Hủy',
      rightButton: 'Đồng ý',
      active: null,
      previewActive: 0,
      previewChildActive: 0,
      sizeChartActive: null,
      contentChildActive: null,
    };
  },
  methods: {
    removeProductType(item) {
      const index = this.attributes.tag.indexOf(item);
      if (index >= 0) this.attributes.tag.splice(index, 1);
    },
    changDescription(dar) {
      this.attributes.descriptionHtml = dar;
    },
    closeDialog() {
      this.statusOfDialog = false;
    },
    deleteItem() {
      this.submitDelete();
      this.statusOfDialog = false;
    },
    onCancel() {
      this.$v.$reset();
      // this.attributes = Object.assign({}, this.defaultForm);
      this.$forceUpdate();
      this.$router.push({ name: 'website.sizechart' });
    },
    async onSubmit() {
      this.$v.$touch();
      utilities.scrollToTop();
      try {
        if (!this.$v.$invalid) {
          this.errors = [];
          this.isLoading = true;

          let convertData = {
            ...this.attributes,
          };
          await sizechartApi.update(this.idItem, this.attributes);
          let res = await sizechartApi.update(this.idItem, convertData);
          this.getData();

          // this.$router.push({ name: 'website.sizechart', params: { id: res.data.id } });
        } else {
          this.errors = ['Validate error'];
        }
      } catch (error) {}

      this.isLoading = false;
    },
    async submitDelete() {
      try {
        sizechartApi.remove(this.idItem);
        this.$router.push({ name: 'website.sizechart' });
      } catch (error) {
        console.log(error);
      }
    },
    async getData() {
      try {
        let params = {
          limit: 200,
          search: this.search,
        };
        // để hiện thị tất cả các product type đang tồn tại
        let data = await productApi.getProductTypes(params);
        this.document = data.data.types;
        // console.log(data);
        this.idItem = this.$route.params.id;
        let res = (await sizechartApi.getById(this.idItem)).data;
        // console.log(res);
        this.name = res.style;
        // this.document bao gồm res.tag
        // this.attributes = {
        //   descriptionHtml: res.descriptionHtml,
        //   imageUrl: res.imageUrl,
        //   tag: res.tag,
        //   style: res.style,
        // };
        console.log('res', res);
        if (!res.sizeCharts) {
          this.attributes = {
            sizeCharts: [
              {
                attributes: [
                  {
                    descriptionHtml: res.descriptionHtml,
                    imageUrl: res.imageUrl,
                    title: '',
                  },
                ],
                title: res.style,
              },
            ],
            style: res.style,
            tag: res.tag,
          };
        } else {
          this.attributes = {
            sizeCharts: res.sizeCharts,
            style: res.style,
            tag: res.tag,
          };
        }
        // this.attributes = {
        //   sizeCharts: [
        //     {
        //       attributes: [
        //         {
        //           descriptionHtml: res.descriptionHtml,
        //           imageUrl: res.imageUrl,
        //           title: '',
        //         },
        //       ],
        //       title: res.style,
        //     },
        //   ],
        //   style: res.style,
        //   tag: res.tag,
        // };
        this.sizeChartActive = this.attributes.sizeCharts[0];
        this.contentChildActive = this.sizeChartActive.attributes[0];
        this.$forceUpdate();
      } catch (error) {
        console.log(error);
      }
    },
    async searchData() {
      this.isLoading = false;
      try {
        let params = {
          search: this.search,
          limit: 200,
        };
        let data = await productApi.getProductTypes(params);
        this.document = data.data.types;
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    change(data, index) {
      this.attributes.sizeCharts[index.i].attributes[index.j].descriptionHtml = data;
    },
    setImage(img, index) {
      this.attributes.sizeCharts[index.i].attributes[index.j].imageUrl = img.src;
    },
    addContent() {
      let obj = {
        attributes: [],
        title: '',
      };
      this.attributes.sizeCharts.push(obj);
    },
    removeContent(i) {
      this.attributes.sizeCharts.splice(i, 1);
    },
    addAttributes(i) {
      let obj = {
        descriptionHtml: '',
        imageUrl: '',
        title: '',
      };
      this.attributes.sizeCharts[i].attributes.push(obj);
      this.active = {
        i: i,
        j: this.attributes.sizeCharts[i].attributes.length - 1,
      };
    },
    removeAttribute(i, j) {
      this.attributes.sizeCharts[i].attributes.splice(j, 1);
    },
    activeChild(i, j) {
      if (this.active && this.active.i === i && this.active.j === j) {
        this.active = null;
      } else {
        this.active = {
          i: i,
          j: j,
        };
      }
    },
    activeSizeChart(i) {
      this.previewActive = i;
      this.sizeChartActive = this.attributes.sizeCharts[i];
      if (this.sizeChartActive && this.sizeChartActive.attributes && this.sizeChartActive.attributes.length > 0) {
        this.contentChildActive = this.sizeChartActive.attributes[0];
      } else {
        this.contentChildActive = {
          descriptionHtml: '',
          imageUrl: '',
          title: '',
        };
      }
    },
    activeContentChild(i) {
      // console.log('active', i);
      this.previewChildActive = i;
      this.contentChildActive = this.sizeChartActive.attributes[i];
      this.$forceUpdate();
    },
  },
  watch: {
    // 'attributes.style': function() {
    //   this.attributes.tag = 'sc-' + this.attributes.style + '-' + this.attributes.tag.slice(-3);
    // },
    search() {
      this.searchData();
    },
    attributes: {
      deep: true,
      handler() {
        if (this.attributes.sizeCharts.length > 0) {
          this.sizeChartActive = this.attributes.sizeCharts[0];
          if (this.sizeChartActive && this.sizeChartActive.attributes.length > 0) {
            this.contentChildActive = this.sizeChartActive.attributes[0];
          } else {
            this.contentChildActive = {
              descriptionHtml: '',
              imageUrl: '',
              title: '',
            };
          }
        }
      },
    },
  },
  validations: {
    attributes: {
      tag: {
        required,
        minLength: minLength(0),
        maxLength: maxLength(255),
      },
      style: {
        required,
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.product-page--create {
  .btn-link {
    color: var(--v-primary-base);
    &:hover {
      text-decoration: none;
    }
  }
  .v-expansion-panel:before {
    box-shadow: none;
  }
  .v-expansion-panel-header__icon {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .sideguide-content {
    word-break: break-all;
  }
  .v-text-field__details {
    height: 0;
  }
}
.asign-btn {
  margin-bottom: 22px;
}
.side-guide {
  min-height: 85px;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
}
.border-child {
  border: 1px solid #ddd;
  &:hover {
    cursor: pointer;
  }
}
.preview-title {
  color: rgb(133, 131, 131);
  &:hover {
    cursor: pointer;
  }
}
.title-active {
  color: #202020;
  text-decoration: underline;
}
.preview-content {
  color: rgb(133, 131, 131);
  border: 1px solid #ddd;
  &:hover {
    cursor: pointer;
  }
}
.content-active {
  background-color: #000;
  color: #fff;
  border: 1px solid transparent;
}
</style>
<style lang="scss">
.size-chart-preview {
  img {
    height: auto !important;
    width: 100% !important;
  }
}
</style>
